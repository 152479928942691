<template>
    <div>
        <v-overlay :value="overlay" absolute style="z-index:999999999">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-icon left>mdi-handshake</v-icon>
                    OP #{{ datosOportunidad.sales_opportunity_id }} :: {{ datosOportunidad.op_estado }}
                </v-toolbar-title>
                <v-divider class="px-1 mx-4" vertical></v-divider>
                <v-btn 
                    color="green" 
                    dark 
                    small
                    @click="guardarOportunidad"
                >
                    <v-icon>mdi-content-save</v-icon>Guardar
                </v-btn>
                <!-- <v-btn v-if="datosOportunidad.stage_type != 'FINAL'" class="ml-4" color="blue" small dark target="_blank"
                    @click="agendarEvento()"><v-icon small>mdi-calendar-plus</v-icon>Agendar cita</v-btn> -->
                <!-- <v-btn 
                    v-if="cotizacionHabilitada && datosOportunidad.requiere_valoracion && !datosOportunidad.valoracion_creada" 
                    class="ml-4" 
                    color="blue" 
                    small 
                    dark
                    target="_blank"
                    @click="generaOrdenValoracion()"
                >
                    <v-icon small>mdi-room-service-outline</v-icon>Orden de valoración
                </v-btn> 

                <v-btn 
                    v-if="cotizacionHabilitada && (!datosOportunidad.requiere_valoracion || datosOportunidad.valoracion_creada)" 
                    class="ml-4" 
                    color="blue" 
                    small 
                    dark
                    target="_blank"
                    @click="generaOrdenServicio"
                >
                    <v-icon small>mdi-room-service-outline</v-icon>Orden de servicio
                </v-btn> 

                <v-btn 
                    v-if="cotizacionHabilitada" 
                    class="ml-4" 
                    color="blue" 
                    small 
                    dark
                    target="_blank"
                    @click="generaOrdenServicio"
                >
                    <v-icon small>mdi-room-service-outline</v-icon>Orden de servicio
                </v-btn>-->                               
                <v-btn
                    v-if="datosOportunidad.stage_type != 'FINAL'" 
                    class="ml-4" 
                    color="blue" 
                    dark 
                    small
                    @click="abrirDialogCambiarEstado"
                >
                    <v-icon small>mdi-filter-settings</v-icon> Cambiar estado
                </v-btn>
                <v-btn
                    v-if="user.permission.includes('CREATE_QUOTE_OPP')" 
                    class="ml-4" 
                    color="blue" 
                    dark 
                    small
                    @click="crearCotizacionOportunidad"
                >
                    <v-icon small>mdi-plus</v-icon> Crear cotización
                </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2">
                <v-col cols="3">
                    <v-text-field 
                        label="Monto estimado" 
                        v-model="datosOportunidad.estimated_amount"
                    />
                </v-col>
                <v-col cols="3">
                    <v-text-field 
                        label="Fecha de cierre estimado"
                        v-model="datosOportunidad.estimated_close_date"
                    />
                </v-col>
                <v-col cols="2">
                    <v-text-field readonly label="Cliente ID" v-model="datosOportunidad.party_id">
                        <template slot="prepend">
                            <v-btn small icon target="_blank" title="ver contacto"
                                :to="`/contacto_form/${datosOportunidad.party_id}`">
                                <v-icon>mdi-account-details</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field readonly label="Contacto" v-model="datosOportunidad.cliente_nombre">
                        <template slot="prepend">
                            <v-btn small icon target="_blank" title="ver contacto"
                                :to="`/contacto_form/${datosOportunidad.party_id}`">
                                <v-icon>mdi-account-details</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-1 mx-2">
                <v-col cols="3">
                    <v-text-field readonly label="Estado" v-model="datosOportunidad.op_estado"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Ficha" v-model="datosOportunidad.ficha"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Nombre" v-model="datosOportunidad.opportunity_name"></v-text-field>
                </v-col>
            </v-row>

            <v-row class="mt-1 mx-2">
                <v-col cols="6">
                    <v-textarea label="Descripcion" v-model="datosOportunidad.description" rows="3"></v-textarea>
                </v-col>
                <v-col cols="6">
                    <!-- <v-text-field 
                        readonly 
                        label="Cotización" 
                        v-model="datosCotizacion.quote_id"
                    >
                        <template v-slot:prepend>
                            <v-btn 
                                icon 
                                small 
                                title="Ver cotización" 
                                target="_blank" 
                                :to="`/crm_cotizacion?accion=crear_op&sop_id=${datosOportunidad.sales_opportunity_id}`"
                            >
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field> -->
                </v-col>

                <v-col cols="6">
                    <v-toolbar flat color="green" class="mt-4" height="35" dark><v-toolbar-title>Items</v-toolbar-title>
                    </v-toolbar>
                    <v-data-table 
                        :headers="headersItems" 
                        :items-per-page="itemsPerPage" 
                        :items="productosOp"
                        class="elevation-1" 
                        small
                    >
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <v-toolbar color="green" dark class="mt-4" height="35" flat>
                        <v-toolbar-title>Cotizaciones</v-toolbar-title>
                    </v-toolbar>
                    <v-data-table 
                        :items-per-page="itemsPerPage" 
                        class="elevation-1" 
                        small 
                        hide-default-footer
                        :items="listaCotizaciones" 
                        :headers="headHistorialCot"
                    >
                        <template v-slot:item.quote_id="{ item }">
                            <v-btn 
                                color="success" 
                                small
                                link
                                target="_blank" 
                                :to="`/crm_cotizacion?accion=crear_op&sop_id=${datosOportunidad.sales_opportunity_id}&quote_id=${item.quote_id}`"
                            >
                                {{ item.quote_id }}
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <v-toolbar color="green" dark class="mt-4" height="45" flat>
                        <v-toolbar-title><v-icon left>mdi-calendar</v-icon>Eventos</v-toolbar-title>
                    </v-toolbar>

                    <v-data-table :items-per-page="itemsPerPage" class="elevation-1" small hide-default-footer
                        :items="listaEventos" :headers="headEventos">
                        <template v-slot:item.work_effort_id="{ item }">
                            <v-btn small text :to="`/evento_form/${item.work_effort_id}`" target="_blank">
                                <v-icon small left>mdi-calendar</v-icon>
                                {{ item.work_effort_id }}
                            </v-btn>
                        </template>
                        <template v-slot:item.fecha="{ item }">
                            {{ item.fecha }} ||
                            {{ item.hora.split(":")[0] + ":" + item.hora.split(":")[1] }}
                        </template>
                    </v-data-table>
                </v-col>

                <v-col cols="6">
                    <v-toolbar color="green" dark class="mt-4" height="45" flat>
                        <v-toolbar-title><v-icon left>mdi-paperclip</v-icon>Adjuntos</v-toolbar-title>
                        <v-divider vertical class="mx-4"></v-divider>
                        <v-tooltip>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-4" @click="abrirAdjuntosCarpeta" small color="blue"
                                    title="Adjuntar carpeta" rounded><v-icon>mdi-folder-upload</v-icon></v-btn>
                                <v-btn class="mx-4" @click="abrirAdjuntos" small color="blue" title="Adjuntar archivo"
                                    rounded><v-icon>mdi-attachment-plus</v-icon></v-btn>
                            </template>
                        </v-tooltip>
                    </v-toolbar>
                    <v-file-input multiple style="display:none" id="archivosAdjuntos" label="adjuntar"
                        v-model="archivoSelecionados" @change="adjuntarArchivo()"></v-file-input>

                    <v-file-input webkitdirectory directory multiple style="display:none" id="carpetasAdjuntas"
                        label="adjuntar" v-model="carpetasSeleccionadas" @change="adjuntarCarpeta()"></v-file-input>

                    <v-data-table :items-per-page="itemsPerPage" class="elevation-1" small hide-default-footer
                        :items="listaAdjuntos" :headers="headAdjuntos">
                        <template v-slot:item.description="{ item }">
                            <v-btn small icon @click="cargarDatosContenido(item)"
                                title="Ver información detallada del archivo"><v-icon small
                                    color="blue">mdi-information-outline</v-icon></v-btn>{{ item.description }}
                        </template>

                        <template v-slot:item.opciones="{ item }">
                            <v-btn v-if="puedoDescargarContenido" small icon title="Descargar archivo"
                                @click="descargarContenido(item)"><v-icon small color="blue">mdi-download</v-icon>

                            </v-btn>

                            <v-btn small icon @click="abrirDialogEditarContenido(item)"
                                title="Editar descripción archivo"><v-icon small
                                    color="green">mdi-pencil</v-icon></v-btn>

                            <v-btn small icon title="Descargar archivo" @click="visualizarContenido(item)"><v-icon small
                                    color="blue">mdi-eye</v-icon></v-btn>



                            <v-btn small icon @click="borrarArchivo(item)" title="Borrar archivo"><v-icon small
                                    color="red">mdi-delete</v-icon></v-btn>
                        </template>
                    </v-data-table>
                    <img id="prevImag" src="" />
                </v-col>

                <v-col cols="6">
                    <v-toolbar color="green" dark class="mt-4" height="45" flat>
                        <v-toolbar-title><v-icon left>mdi-room-service</v-icon>Ordenes de servicio</v-toolbar-title>
                    </v-toolbar>

                    <v-data-table 
                        :items-per-page="itemsPerPage" 
                        class="elevation-1" 
                        small 
                        hide-default-footer
                        :items="ordenesServicio" 
                        :headers="headOrdenServicio"
                    >
                        <template v-slot:item.orden_servicio_id="{ item }">
                            <v-btn small text :to="`/genera-orden-servicio/${item.quote_id}/${item.orden_servicio_id}`" target="_blank">
                                <v-icon small left>mdi-room-service</v-icon>
                                {{ item.orden_servicio_id }}
                            </v-btn>
                        </template>
                        <template v-slot:item.tipo_servicio="{ item }">
                            {{ item.tipo_servicio }} <b>{{ item.valoracion ? '(VALORACIÓN)' : '' }}</b>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <!-- DIALOG AGENDAR EVENTO  -->
        <v-dialog v-model="dialogAgendarEvento" scrollable persistent content-class="dialogofh">
            <v-form ref="formAgendarEvento">
                <v-card class="pa-0 ma-0">
                    <v-toolbar dark color="blue" height="40" flat>
                        Agendar Evento
                        <v-spacer></v-spacer>
                        <v-btn small text @click="cerrarDialogoAgendar">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <AgendamientoComponent :partyIdClient="datosOportunidad.party_id"
                            :oportunidadId="datosOportunidad.sales_opportunity_id" />
                    </v-card-text>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- DIALOG CAMBIAR ESTADO -->
        <v-dialog v-model="dialogCambioEstado" scrollable persistent width="420">
            <v-form ref="formCambioEstado">
                <v-card class="pa-0 ma-0">
                    <v-toolbar dark color="blue" height="40" flat>
                        Cambiar de estado a la oportunidad
                        <v-spacer></v-spacer>
                        <v-btn small text @click="cerrarDialogoCambioEstado">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-col>
                            <v-select clearable v-model="nuevoEstadoId" label="Estado"
                                @change="cargarListaOportunidades" :items="estados" item-text="description"
                                item-value="opportunity_stage_id" :rules="requiredRule">
                            </v-select>
                        </v-col>
                        <v-col v-if="motivosEstados.filter(e => e.opportunity_stage_id == nuevoEstadoId).length">
                            <v-select clearable v-model="motivoEstadoId" label="Motivo"
                                :items="motivosEstados.filter(e => e.opportunity_stage_id == nuevoEstadoId)"
                                item-text="descripcion" item-value="sale_opportunity_stage_motivo_id"
                                :rules="requiredRule">
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-textarea :rules="requiredRule" label="Observaciones" rows="3"
                                v-model="estadoObs"></v-textarea>
                        </v-col>
                    </v-card-text>
                    <v-toolbar flat class="ma-2">
                        <v-btn dark @click="guardarEstado" small color="green"><v-icon left
                                small>mdi-content-save</v-icon>Guardar</v-btn>

                        <v-btn class="pl-4" text @click="cerrarDialogoCambioEstado" small><v-icon left
                                small>mdi-cancel</v-icon>Cancelar</v-btn>
                    </v-toolbar>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- DIALOGO EDITAR DESCRIPCION ARCHIVO -->
        <v-dialog v-model="dialogEditarContenido" width="400">
            <v-form ref="formEditarContenido">
                <v-card class="pa-0 ma-0">
                    <v-toolbar dark color="blue" height="40" flat>
                        Descripción archivo
                        <v-spacer></v-spacer>
                        <v-btn small text @click="dialogEditarContenido = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-row class="px-0 ma-2">
                        <v-col cols="12">
                            <v-text-field :rules="requiredRule" v-model="datosContenido.description"
                                label="Descripción"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="px-0 ma-2">
                        <v-col cols="6">
                            <v-btn small color="primary" dark @click="editarContenido()"><v-icon small
                                    left>mdi-content-save</v-icon>Guardar</v-btn>
                        </v-col>
                    </v-row>

                </v-card>
            </v-form>
        </v-dialog>

        <!-- DIALOGO DATOS ARCHIVO -->
        <v-dialog v-model="dialogDatosContenido" width="600">
            <v-card class="pa-0 ma-0">
                <v-toolbar dark color="blue" height="40" flat>
                    Datos contenido
                    <v-spacer></v-spacer>
                    <v-btn small text @click="dialogDatosContenido = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-row class="px-0 ma-2">
                    <v-col cols="12">
                        <v-text-field readonly class="py-0 my-0" v-model="infoContenido.nombre_archivo"
                            label="Nombre del archivo"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field readonly class="py-0 my-0" v-model="infoContenido.description"
                            label="Descripción "></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field readonly class="py-0 my-0" v-model="infoContenido.estado"
                            label="Estado"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field readonly class="py-0 my-0" v-model="infoContenido.created_by_user_login"
                            label="Creado por"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field readonly class="py-0 my-0" v-model="infoContenido.created_date"
                            label="Fecha de creación"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field readonly class="py-0 my-0" v-model="infoContenido.last_modified_by_user_login"
                            label="Modificado por"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field readonly class="py-0 my-0" v-model="infoContenido.last_modified_date"
                            label="Ultima fecha de modificación"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <!-- DIALOGO DATOS HISTORIAL DE CONTENIDO -->
        <v-dialog v-model="dialogHistorialContenido" persistent width="700"></v-dialog>

    </div>
</template>
<style>
    .dialogofh:not(.v-dialog--fullscreen) {
        max-height: 100%;
    }

    .dialogofh {
        position: relative;
        margin: 0px;
        width: 50%;
    }
</style>
<script>

import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AgendamientoComponent from "./AgendamientoComponent.vue";
import axios from "axios";
import Vue from "vue";

export default {
    components: { AgendamientoComponent },
    name: "ListaOportunidades",
    comments: {
        AgendamientoComponent,
    },
    data: () => ({
        show: false,
        requiredRule: [(v) => !!v || "El campo es requerido"],
        options: {},
        setfilters: [],
        selected: [],
        users: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 20,
        pageCount: 1,
        overlay: false,
        required: [(v) => !!v || "El campo es requerido"],
        headers: [
            { text: "Número", align: "start", value: "sales_opportunity_id" },
            { text: "Pipeline", align: "start", value: "pipeline_name" },
            { text: "Contacto", align: "start", value: "cliente_nombre" },
            { text: "Estado", align: "start", value: "sopp_estado" },
            { text: "Monto", align: "start", value: "estimated_amount" },
            { text: "Fecha estimada de cierre", align: "start", value: "estimated_close_date" },
            { text: "Asignado a", align: "start", value: "created" },
        ],
        oportunidades: [],
        motivosEstados: [],
        dialogoOportunidad: false,
        motivoEstadoId: null,
        datosOportunidad: {
            sales_opportunity_id: "",
            party_id: "",
            monto: 0,
            fecha_estimada_cierre: "",
            estado_id: "",
            estado: "",
            opportunity_name: "",
            cliente_nombres: "",
            description: "",
            next_step: "",
        },
        datosCotizacion: {},
        productosOp: [],
        headersItems: [
            { text: "ID", align: "start", value: "id" },
            { text: "Producto", align: "start", value: "product_name" },
            { text: "Cantidad", align: "start", value: "cantidad" },
            { text: "Valor unitario", align: "start", value: "precio" },
            { text: "Descuento", align: "start", value: "valor_descuento" },
            { text: "%IVA", align: "start", value: "pct_imp" },
            { text: "Total", align: "start", value: "total" },
        ],
        headHistorialCot: [
            { text: "Numero", align: "start", value: "quote_id" },
            { text: "Fecha", align: "start", value: "issue_date" },
            { text: "Estado", align: "start", value: "estado_cot" },
            { text: "Total", align: "start", value: "total" },
        ],
        listaCotizaciones: [],
        archivoActual: "",
        listaAdjuntos: [],
        headAdjuntos: [
            { text: "Descripción", value: "description" },
            { text: "Opciones", value: "opciones" },
        ],
        headEventos: [
            { text: "ID", align: "start", value: "work_effort_id" },
            { text: "Fecha", align: "start", value: "fecha" },
            { text: "Estado", align: "start", value: "estado" },
            { text: "Creado por", align: "start", value: "created_by_user_login" },
        ],
        listaEventos: [],
        camposOp: [],
        headOrdenServicio: [
            { text: "Numero", align: "start", value: "orden_servicio_id" },
            { text: "Fecha", align: "start", value: "created_stamp" },
            { text: "Estado", align: "start", value: "estado" },
            { text: "Tipo de servicio", align: "start", value: "tipo_servicio" },
        ],
        ordenesServicio: [],
        pipelines: [],
        estados: [],
        dialogAgendarEvento: false,
        dialogCambioEstado: false,
        datosEstadoActual: {},
        estadoObs: "",
        nuevoEstadoId: "",
        pipelineId: "",
        archivoSelecionados: null,
        carpetasSeleccionadas: null,
        dialogEditarContenido: false,
        dialogDatosContenido: false,
        dialogHistorialContenido: false,
        oppCotFacturada:false,
        datosContenido: { content_id: "", description: "" },
        infoContenido: {},
    }),
    computed: {

        ...mapState("master", [
            "loadingBtn",
            "companies",
            "urlExternal",
            "token",
            "url",
            "tenantId",
        ]),

        ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),
        
        ...mapGetters('access', ["puedoDescargarContenido"]),

        cotizacionHabilitada : function(){

            return !['QUO_CANCELED','QUO_CREATED','QUO_REJECTED'].includes(this.datosCotizacion.status_id)

        }
        
        /*estadosPipeline() {
          let ep = [];
          for (let i = 0; i < this.estados.length; i++) {
            if (this.estados[i].pipeline_id == this.pipelineId) {
              ep.push(this.estados[i]);
            }
          }
          return ep;
        },*/
    },

    methods: {

        ...mapMutations("master", [
            "setUrl",
            "setLoadingTable",
            "setTitleToolbar",
            "setTenantId"
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),

        cargarListaOportunidades() {
            this.setLoadingTable(true);
            this.setUrl("oportunidad");
            this.requestApi({
                method: "GET",
                data: {
                    view_type: "lista",
                    page: this.currentPage,
                    page_count: this.pageCount,
                    page_size: this.itemsPerPage,
                    sales_opportunity_id: this.busCodigo,
                    pipeline_id: this.pipelineId,
                    opportunity_stage_id: this.busEstado
                },
            })
                .then((res) => {
                    //console.log(res.data);
                    this.oportunidades = res.data._embedded.oportunidad;
                    this.pageCount = res.data.page_count;
                    //this.currentPage = res.data.page;
                    this.totalItems = res.data.total_items;
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        cargarDatosOportunidad(id) {
            this.setLoadingTable(true);
            this.overlay = true;
            this.setUrl("oportunidad/" + id);
            this.requestApi({
                method: "GET",
                data: {},
            })
                .then((res) => {
                    //console.log(res.data.detail);

                    this.productosOp = res.data.detail.items_op;

                    this.datosOportunidad = res.data.detail.oportunidad;
                    this.datosCotizacion = res.data.detail.cotizacion;
                    this.listaCotizaciones = res.data.detail.cotizaciones;
                    this.listaAdjuntos = res.data.detail.adjuntos;
                    this.listaEventos = res.data.detail.we_op;
                    this.pipelines = res.data.detail.pipelines;
                    this.estados = res.data.detail.estados;
                    this.ordenesServicio = res.data.detail.ordenes_servicio;
                    this.motivosEstados = res.data.detail.estado_motivos
                    this.oppCotFacturada = res.data.detail.facturado
                    this.overlay = false;
                })
                .then(() => {
                    this.setLoadingTable(false);
                    this.overlay = false;
                });
        },

        guardarOportunidad() {
            if (this.$re) this.setLoadingTable(true);
            this.setUrl("oportunidad");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "modificarOportunidad",
                    datosOportunidad: this.datosOportunidad,
                },
            })
                .then((res) => {
                    //console.log(res.data.detail);
                    this.alertNotification({
                        param: {
                            html: res.data.detail.msg,
                        },
                    });
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        handlePageChange() {
            this.cargarListaOportunidades();
        },

        adjuntarArchivo() {
            //console.log(this.archivoSelecionados);

            let tamanoTotal = 0;
            for (let i = 0; i < this.archivoSelecionados.length; i++) {
                tamanoTotal += this.archivoSelecionados[i].size;
            }

            if (this.archivoSelecionados.length > 10 && tamanoTotal < 10000000) {
                alert("Va a cargar muchos archivos está seguro ? ");
                return false;
            }

            if (tamanoTotal >= 100000000) {
                alert("Va a cargar archivos por más de 100MB está seguro ? ");
                return false;
            }
            let formData = new FormData();
            formData.append(
                "sales_opportunity_id",
                this.datosOportunidad.sales_opportunity_id
            );
            formData.append("tenantId", this.tenantId);
            formData.append("accion", "adjuntarArchivo");
            formData.append("user", this.user.user_ligin_id);

            for (let i = 0; i < this.archivoSelecionados.length; i++) {
                formData.append("archivos[]", this.archivoSelecionados[i]);
            }
            this.overlay = true;
            this.setUrl("oportunidad");
            axios
                .post(this.url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + this.token,
                    },
                })
                .then((res) => {
                    //this.setLoadingBtn(false);
                    //console.log(res);
                    this.overlay = false;
                    this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
                    this.alertNotification({
                        param: {
                            html: res.data.detail,
                        },
                    });
                })
                .catch((err) => {
                    //console.log(err);
                    this.overlay = false;
                    //this.setLoadingBtn(false);

                    /*params = {
                      title: "Error!",
                      html: err.data.detail,
                      icon: "error",
                      confirmButtonColor: "#d60400",
                      toast: false,
                      grow: false,
                      position: "center",
                      confirmButtonText: "Cerrar",
                    };
          
                    Vue.swal({ ...params });*/
                })
                .finally((err) => {
                    //console.log(err);
                    this.overlay = false;
                });
        },

        adjuntarCarpeta() {
            let tamanoTotal = 0;
            for (let i = 0; i < this.carpetasSeleccionadas.length; i++) {
                tamanoTotal += this.carpetasSeleccionadas[i].size;
            }

            if (this.carpetasSeleccionadas.length > 10 && tamanoTotal < 10000000) {
                alert("Va a cargar muchos archivos está seguro ? ");
                return false;
            }

            if (tamanoTotal >= 100000000) {
                alert("Va a cargar archivos por más de 100MB está seguro ? ");
                return false;
            }

            this.overlay = true;
            let formData = new FormData();
            formData.append(
                "sales_opportunity_id",
                this.datosOportunidad.sales_opportunity_id
            );
            formData.append("tenantId", this.tenantId);
            formData.append("accion", "adjuntarArchivo");
            formData.append("user", this.user.user_ligin_id);

            for (let i = 0; i < this.carpetasSeleccionadas.length; i++) {
                formData.append("archivos[]", this.carpetasSeleccionadas[i]);
            }
            this.setUrl("oportunidad");
            axios
                .post(this.url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + this.token,
                    },
                })
                .then((res) => {
                    //this.setLoadingBtn(false);
                    //console.log(res);
                    this.overlay = false;
                    this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
                    this.alertNotification({
                        param: {
                            html: res.data.detail,
                        },
                    });
                })
                .catch((err) => {
                    //console.log(err);
                    this.overlay = false;
                    //this.setLoadingBtn(false);

                    /*params = {
                      title: "Error!",
                      html: err.data.detail,
                      icon: "error",
                      confirmButtonColor: "#d60400",
                      toast: false,
                      grow: false,
                      position: "center",
                      confirmButtonText: "Cerrar",
                    };
          
                    Vue.swal({ ...params });*/
                })
                .finally((err) => {
                    this.overlay = false;
                });
        },
        
        agendarEvento() {
            this.dialogAgendarEvento = true;
        },

        cerrarDialogoAgendar() {
            this.dialogAgendarEvento = false;
            this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
        },

        abrirDialogCambiarEstado() {
            this.dialogCambioEstado = true;
        },

        cerrarDialogoCambioEstado() {
            this.dialogCambioEstado = false;
        },

        guardarEstado() {
            if (!this.$refs.formCambioEstado.validate()) {
                return false;
            }
            this.setUrl("oportunidad");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "cambiarEstadoA",
                    sales_opportunity_id: this.datosOportunidad.sales_opportunity_id,
                    status_id: this.nuevoEstadoId,
                    motivoEstadoId: this.motivoEstadoId
                },
            })
                .then((res) => {
                    //console.log(res.data);
                    this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
                    this.cerrarDialogoCambioEstado();
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        abrirAdjuntos() {
            document.getElementById("archivosAdjuntos").click();
        },

        abrirAdjuntosCarpeta() {
            document.getElementById("carpetasAdjuntas").click();
        },

        borrarArchivo(item) {

            Vue.swal({
                html: "Está seguro de eliminar este  archivo ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setUrl("oportunidad");
                    this.requestApi({
                        method: "POST",
                        data: {
                            accion: "borrarContenido",
                            sales_opportunity_id: item.sales_opportunity_id,
                            content_id: item.content_id,
                        },
                    })
                    .then((res) => {
                        this.cargarDatosOportunidad(
                            this.$route.params.sales_opportunity_id
                        );
                    })
                    .then(() => {
                        this.setLoadingTable(false);
                    });
                }
            });
        },

        abrirDialogEditarContenido(item) {
            this.dialogEditarContenido = true;
            this.datosContenido.content_id = item.content_id;
            this.datosContenido.description = item.description;
        },

        editarContenido() {
            if (!this.$refs.formEditarContenido.validate()) {
                return false;
            }
            this.setUrl("oportunidad");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "modificarContenido",
                    datos_contenido: this.datosContenido,
                },
            })
                .then((res) => {
                    this.dialogEditarContenido = false;
                    this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        descargarContenido(item) {

            this.overlay = true
            this.setUrl("oportunidad")

            this.requestApi({
                method: "POST",
                data: {
                    accion: "descargarContenido",
                    content_id: item.content_id,
                },
            })
                .then((res) => {
                    var a = document.createElement("a");
                    a.href =
                        "data:" +
                        res.data.detail.mime_type +
                        ";base64," +
                        res.data.detail.datos;
                    a.download = res.data.detail.archivo;
                    a.click();
                    this.overlay = false;
                })
                .then(() => {
                    this.overlay = false;
                });
        },

        visualizarContenido(item) {
            this.overlay = true;
            this.setUrl("oportunidad");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "descargarContenido",
                    content_id: item.content_id,
                },
            })
                .then((res) => {

                    /* let img = document.createElement("img");    
           
                     img.style ="pointer-events: none";
                     img.src =
                       "data:" +
                       res.data.detail.mime_type +
                       ";base64," +
                       res.data.detail.datos;*/


                    let obj = document.createElement("object");

                    obj.style = "width:100%; height:100%; ";
                    obj.id = "contenido";
                    if (res.data.detail.size != false) {
                        obj.style = "width:" + res.data.detail.size[0] + ";height:" + res.data.detail.size[1];
                    }

                    obj.type = res.data.detail.mime_type;
                    obj.data = "data:" + res.data.detail.mime_type + ";base64," + res.data.detail.datos;

                    let newWin = null;
                    if (res.data.detail.size != false) {
                        newWin = window.open("about:blank", "visualizar", "width=" + res.data.detail.size[0] + ",height=" + res.data.detail.size[1] + "");
                    } else {
                        newWin = window.open("about:blank", "visualizar", "");
                    }

                    newWin.document.write("<script>");
                    newWin.document.write(" document.addEventListener('contextmenu',function(e) { e.preventDefault();}); document.addEventListener('keydown',function(e) { if(e.ctrlKey || e.metaKey) {e.preventDefault();}}); ");
                    newWin.document.write("<\/script>");
                    let html = '';
                    html += '<html>';
                    html += '<body style="margin:0!important">';
                    if (res.data.detail.mime_type.includes("image") || res.data.detail.mime_type.includes("pdf")) {



                        html += obj.outerHTML;
                    } else {
                        html += "<h2 style='color:grey'>NO SE PUEDE VISUALIZAR ESTE ARCHIVO</h2>";
                    }


                    html += '</body>';
                    html += '</html>';
                    newWin.document.write(html);

                    this.overlay = false;
                })
                .then(() => {
                    this.overlay = false;
                });
        },

        cargarDatosContenido(item) {
            this.setUrl("oportunidad");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "datosContenido",
                    content_id: item.content_id,
                },
            })
                .then((res) => {
                    //this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
                    this.infoContenido = res.data.detail.datos_contenido;
                    this.dialogDatosContenido = true;
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        /* generaOrdenValoracion(){         

            this.overlay = true

            this.setUrl("oportunidad")

            this.requestApi({
                method: "POST",
                data: {
                    accion: "generaOrdenValoracion",
                    salesOpportunityId: this.$route.params.sales_opportunity_id
                }
            }).then(res => {

                console.log('res',res)

                this.overlay = false
            
                this.alertNotification({
                    param: {
                        title: res.data.success ? 'Éxito' : 'Error',
                        icon: res.data.success ? 'success' : 'error',
                        html: res.data.msg
                    },
                })

                if(res.data.success)
                    for (const quoteId in res.data.quotes)                                            
                        window.open(`/genera-orden-servicio/${quoteId}/${res.data.quotes[quoteId]}/${this.$route.params.sales_opportunity_id}`, "_self")
                
            }).then(() => {
                this.overlay = false
            }).catch(() => {
                this.overlay = false
            })

        }, */

        generaOrdenServicio(){

            window.open(`/genera-orden-servicio/${this.datosCotizacion.quote_id}`, "_blank")

        },

        crearCotizacionOportunidad(){

            Vue.swal({
                html: "Está seguro de crear una nueva cotización a la oportunindad?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {
                
                if (result.isConfirmed) {

                    this.setUrl("oportunidad")

                    this.requestApi({
                        method: "POST",
                        data: {
                            accion: "creaCotizacionOportunidad",
                            opportunityId: this.$route.params.sales_opportunity_id
                        },
                    })
                    .then(res => {

                        console.log('res',res)

                        if(res.data.success){

                            const routeData = this.$router.resolve({
                                name: "CrmCotizacion",
                                query: {
                                    accion: "crear_op",
                                    sop_id: this.$route.params.sales_opportunity_id,
                                    quote_id: res.data.quoteId
                                }
                            })

                            window.open(routeData.href, "_blank")

                        }
                        //res.data.quoteId
                    
                    })
                    .then(() => {
                        
                    })

                }

            })

        }

    },

    mounted() {
        //this.cargarListaOportunidades();
        //this.cargarCampos();
        console.log(this.user)
        this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
        this.setTitleToolbar("OPORTUNIDAD");
    },
};
</script>
